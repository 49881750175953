import { z } from 'zod';

export const termsAndConditions = z.object({
  id: z.string().uuid(),
  version: z.number(),
});

export type TermsAndConditions = z.infer<typeof termsAndConditions>;

export const termsAndConditionsUserAcceptanceSchema = z.object({
  accepted: z.boolean(),
  tcsExist: z.boolean(),
  message: z.string().nullable(),
});

export type TermsAndConditionsUserAcceptance = z.infer<typeof termsAndConditionsUserAcceptanceSchema>;
