import { type Durations, type TimeDefinition, durationEnum, timeDefinitionEnum } from '~/schemas/enums';

const granularitiesToTimeDefinitionsMap: Map<Durations, TimeDefinition> = new Map([
  [durationEnum.enum.ALL, timeDefinitionEnum.enum.HOURLY],
  [durationEnum.enum.PT5M, timeDefinitionEnum.enum.FIVE_MINUTE],
  [durationEnum.enum.PT15M, timeDefinitionEnum.enum.FIFTEEN_MINUTE],
  [durationEnum.enum.PT30M, timeDefinitionEnum.enum.THIRTY_MINUTE],
  [durationEnum.enum.PT1H, timeDefinitionEnum.enum.HOURLY],
  [durationEnum.enum.P1D, timeDefinitionEnum.enum.DAILY],
  [durationEnum.enum.P1M, timeDefinitionEnum.enum.MONTHLY],
]);

const timeDefinitions = [
  timeDefinitionEnum.enum.FIVE_MINUTE,
  timeDefinitionEnum.enum.FIFTEEN_MINUTE,
  timeDefinitionEnum.enum.THIRTY_MINUTE,
  timeDefinitionEnum.enum.HOURLY,
  timeDefinitionEnum.enum.DAILY,
  timeDefinitionEnum.enum.MONTHLY,
  timeDefinitionEnum.enum.QUARTERLY,
  timeDefinitionEnum.enum.YEARLY,
  timeDefinitionEnum.enum.YEARLY_UK_COMPLIANCE_PERIOD,
  timeDefinitionEnum.enum.WHOLE_PERIOD,
];

export const durationToTimeDefinition = (duration: Durations) =>
  granularitiesToTimeDefinitionsMap.get(duration) as TimeDefinition;

export const durationsToTimeDefinitions = (durations: Durations[] | undefined) => {
  if (!durations) return [];
  return durations.map((g) => durationToTimeDefinition(g)).filter((g) => g) as TimeDefinition[];
};

export const getCoarsestTimeDefinitions = (timeDefinition: TimeDefinition) => {
  const coarsestTimeDefinitionIndex = timeDefinitions.indexOf(timeDefinition);
  return timeDefinitions.slice(coarsestTimeDefinitionIndex);
};

export const getCoarserTimeDefinitions = (durations: Durations[] | undefined) => {
  if (!durations || durations.length === 0) return [];

  // Convert durations to time definitions
  const timeDefinitionsSet = new Set(durationsToTimeDefinitions(durations));

  // Find the finest time definition
  const finestTimeDefinition = timeDefinitions.find((td) => timeDefinitionsSet.has(td));

  if (!finestTimeDefinition) return [];

  // Get the index of the finest time definition
  const startIndex = timeDefinitions.indexOf(finestTimeDefinition);

  // Return all time definitions from the finest onwards
  return timeDefinitions.slice(startIndex);
};

export const getApplicableTimeDefinitionsFromMaxDuration = (maxDuration: Durations): TimeDefinition[] => {
  if (maxDuration === durationEnum.enum.ALL) {
    return timeDefinitions;
  }

  const timeDefinition = durationToTimeDefinition(maxDuration);

  const index = timeDefinitions.indexOf(timeDefinition);
  if (index === -1) {
    return [];
  }

  return timeDefinitions.slice(index);
};
