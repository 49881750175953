import { z } from 'zod';
import { durationEnum, intervalEnum, timeAttributeEnum, timeDefinitionEnumLower } from '~/schemas/enums';
import { valueUnitMetricSchema } from './shared';

export const portfolioKpiSchema = z.object({
  portfolioKpi: z.object({
    overviewGlobalMatching: z.object({
      unit: z.string(),
      data: z.object({
        consumption: z.number(),
        matchingGenerationTotal: z.number(),
        matchingGenerationAllocated: z.number(),
        percentageMatchingGenerationAllocated: z.number(),
        percentageMatchingGenerationUnallocated: z.number(),
        matchingGenerationUnallocated: z.number(),
        unmatchedConsumption: z.number(),
        excessGenerationTotal: z.number(),
        excessGenerationUnallocated: z.number(),
        excessGenerationAllocated: z.number(),
        generation: z.number(),
        generationAllocated: z.number(),
        generationUnallocated: z.number(),
      }),
    }),
  }),
});

export type PortfolioKPI = z.infer<typeof portfolioKpiSchema>;
export type PortfolioKPIOverviewGlobalMatching = z.infer<
  typeof portfolioKpiSchema
>['portfolioKpi']['overviewGlobalMatching'];

export const portfolioOverTimeMatchingSchema = z.object({
  portfolioKpi: z.object({
    overTimeMatching: z.object({
      unit: z.string(),
      interval: intervalEnum,
      data: z.array(
        z.object({
          date: z.string(),
          consumption: z.number(),
          matchingGenerationAllocated: z.number(),
          matchingGenerationUnallocated: z.number(),
          unmatchedConsumption: z.number(),
          excessGenerationUnallocated: z.number(),
          excessGenerationAllocated: z.number(),
        }),
      ),
    }),
  }),
});

export type OverTimeMatching = z.infer<typeof portfolioOverTimeMatchingSchema>;
export type OverTimeMatchingData = z.infer<typeof portfolioOverTimeMatchingSchema>['portfolioKpi']['overTimeMatching'];

export const portfolioLongRunAverageMatching = z.object({
  portfolioKpi: z.object({
    longRunAverageMatching: z.object({
      unit: z.string(),
      timeAttribute: timeAttributeEnum,
      data: z.array(
        z.object({
          timeIndex: z.string(),
          consumption: z.number(),
          matchingGenerationAllocated: z.number(),
          matchingGenerationUnallocated: z.number(),
          unmatchedConsumption: z.number(),
          excessGenerationUnallocated: z.number(),
          excessGenerationAllocated: z.number(),
        }),
      ),
    }),
  }),
});

export type LongRunAverageMatching = z.infer<typeof portfolioLongRunAverageMatching>;
export type LongRunAverageMatchingData = z.infer<
  typeof portfolioLongRunAverageMatching
>['portfolioKpi']['longRunAverageMatching'];

export const portfolioOverviewRequestSchema = z.object({
  start_datetime: z.string(),
  end_datetime: z.string(),
  consumer_ids: z.array(z.string()).nullable(),
  production_device_ids: z.array(z.string()).nullable(),
  time_definitions: z.array(timeDefinitionEnumLower).nullable(),
  max_duration: durationEnum.nullable(),
});

export type PortfolioOverviewRequest = z.infer<typeof portfolioOverviewRequestSchema>;

export const portfolioOverviewSchema = z.object({
  consumption: valueUnitMetricSchema,
  generation: valueUnitMetricSchema,
  metrics: z.array(
    z.object({
      time_definition: timeDefinitionEnumLower,
      generation_allocated: valueUnitMetricSchema,
      generation_unallocated: valueUnitMetricSchema,
      matching_generation_total: valueUnitMetricSchema,
      matching_generation_allocated: valueUnitMetricSchema,
      matching_generation_unallocated: valueUnitMetricSchema,
      percentage_matching_generation_allocated: z.number(),
      percentage_matching_generation_unallocated: z.number(),
      excess_generation_total: valueUnitMetricSchema,
      excess_generation_allocated: valueUnitMetricSchema,
      excess_generation_unallocated: valueUnitMetricSchema,
      unmatched_consumption: valueUnitMetricSchema,
    }),
  ),
});

export type PortfolioOverview = z.infer<typeof portfolioOverviewSchema>;
